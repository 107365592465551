<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Edit Expense Type </h2>
          <div>
            <router-link :to="{ name: previousNamedRoute }">
              <button type="button" class="btn btn-danger glow mb-1">
                            <span class>
                                <i class="bx bx-x"></i>
                            </span>
                <span class="align-middle ml-25">Cancel</span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="users-list-table">
              <div class="card">
                <div class="card-body">
                  <div class="row py-1">
                    <div class="col-12 col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Category name *</label>
                        <VueMultiselect v-model="selectedExpenseCategory" class="" :options="expenseCategories"
                                        :close-on-select="true"
                                        placeholder="Select expense category" label="name" track-by="value"
                                        :show-labels="false"/>
                        <div class="text-danger" v-if="errors.expense_category_id">{{ errors.expense_category_id }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6 ">
                      <div class="form-group">
                        <div class="">
                          <label>Type Name *</label>
                          <input v-model="putExpenseTypeData.name" type="text" class="form-control"
                                 placeholder="Type Name">
                        </div>
                        <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <button @click="updateSingleExpenseType" type="button" class="btn btn-primary glow mb-0">
                      <span class="align-middle ml-25">Update</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "expenseTypeEdit",
  components: {
    AppLayout,
  },
  mixins: [ShowToastMessage],

  data() {
    return {
      initialExpenseTypeData: {
        name: '',
        expense_category_id: ''
      },
      putExpenseTypeData: {
        name: '',
        expense_category_id: '',
      },
      getExpenseTypeParams: {
        with_relation: ['expenseCategory']
      },
      expenseTypeId: '',
      selectedExpenseCategory: {
        value: '',
        name: 'None',
      },
      errors: {
        name: '',
        expense_category_id: '',
      }

    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      expenseType: 'appExpenseTypes/expenseType',

    }),
    isExpenseTypeDataChanged() {
      return !((this.initialExpenseTypeData.name === this.putExpenseTypeData.name) &&
          (this.initialExpenseTypeData.expense_category_id === this.putExpenseTypeData.expense_category_id));
    },
    expenseCategories() {
      let categories = this.$store.getters['appExpenseCategories/expenseCategories'].map((category) => {
        let id = category.id;
        let name = category.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...categories];
    },
  },
  watch: {
    selectedExpenseCategory() {
      this.putExpenseTypeData.expense_category_id = this.selectedExpenseCategory.value;
    }
  },
  methods: {
    ...mapActions({
      getExpenseType: 'appExpenseTypes/getExpenseType',
      putExpenseType: 'appExpenseTypes/putExpenseType',
      getExpenseCategories: 'appExpenseCategories/getExpenseCategories'
    }),
    async getSingleExpenseType(id) {
      const paramObj = {
        id: id,
        params: this.getExpenseTypeParams,
      };
      await this.getExpenseType(paramObj).then((response) => {
        if (response.status === 200) {
          //assigning Ids
          this.expenseTypeId = this.expenseType.id;
          //expense category model
          if (this.expenseType.expense_category && this.expenseType.expense_category.id) {
            let categoryIndex = this.expenseCategories.findIndex(item => item.value === this.expenseType.expense_category.id);
            if (categoryIndex !== -1) {
              let category = this.expenseCategories[categoryIndex];

              this.selectedExpenseCategory = {
                value: category.value,
                name: category.name,
              }
            }
          }

          this.initialExpenseTypeData = {
            name: this.expenseType && this.expenseType.name ? this.expenseType.name : '',
            expense_category_id: this.selectedExpenseCategory.value,

          };
          this.putExpenseTypeData = {
            name: this.expenseType && this.expenseType.name ? this.expenseType.name : '',
            expense_category_id: this.selectedExpenseCategory.value,
          };
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    async updateSingleExpenseType() {
      console.log(this.isExpenseTypeDataChanged);
      if (this.isExpenseTypeDataChanged) {
        const expenseTypePramObj = {
          id: this.expenseTypeId,
          data: this.putExpenseTypeData,
        };
        await this.putExpenseType(expenseTypePramObj).then((response) => {
          if (response.status === 200) {
            let toastObj = {message: 'Expense Type related info has Updated', type: 'success'};
            this.showToastMessage(toastObj);
          }

          this.errors.expense_category_id = response.errors && response.errors.expense_category_id ? response.errors.expense_category_id[0] : '';
          this.errors.name = response.errors && response.errors.name ? response.errors.name[0] : '';
          if (response.message) {
            this.showToastMessage(response);
          }
        });
      } else {
        this.showToastMessage({message: ''});
      }

      if(this.errors.expense_category_id || this.errors.name){
        return;
      }
      await this.$router.replace({
        name: 'app.expense.type.list',
      });
    },
  },
  mounted() {
    const routeParams = this.$route.params;
    this.getExpenseCategories();
    this.getSingleExpenseType(routeParams.id);

  },

}
</script>

<style scoped>

</style>
