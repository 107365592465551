<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Create Expense Type </h2>
          <div>
            <router-link :to="{...previousRoute}">
              <button type="button" class="btn btn-danger glow mb-1">
                <span class><i class="bx bx-x"></i></span>
                <span class="align-middle ml-25">Cancel</span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="users-list-table">
              <div class="card">
                <div class="card-body">
                  <div class="row py-1">
                    <div class="col-12 col-lg-6 col-md-6">
                      <div class="form-group">
                        <label>Category name *</label>
                        <VueMultiselect v-model="selectedExpenseCategory" class="" :options="expenseCategories"
                                        :close-on-select="true"
                                        placeholder="Select expense category" label="name" track-by="value"
                                        :show-labels="false"/>
                        <div class="text-danger" v-if="errors.expense_category_id">{{ errors.expense_category_id }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-md-6 ">
                      <div class="form-group">
                        <div class="">
                          <label>Type Name *</label>
                          <input v-model="postExpenseTypeData.name" type="text" class="form-control"
                                 placeholder="Type Name">
                        </div>
                        <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <button @click="createExpenseType" type="button" class="btn btn-primary glow mb-0">
                      <span class="align-middle ml-25">Create</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

import {
  mapActions, mapGetters,

} from "vuex";

export default {
  name: "ExpenseTypeCreate",
  mixins: [ShowToastMessage],
  components: {
    AppLayout,

  },
  data() {
    return {
      selectedExpenseCategory: {
        value: '',
        name: 'None'
      },
      expenseTypeName: '',
      expenseCategory: '',
      getExpenseCategoriesParams: {
        status: 1,
      },
      postExpenseTypeData: {
        name: '',
        expense_category_id: ''
      },
      errors: {
        name: '',
        expense_category_id: '',
      }
    }
  },
  watch: {
    selectedExpenseCategory() {
      this.postExpenseTypeData.expense_category_id = this.selectedExpenseCategory.value
    }
  },
  computed: {
   ...mapGetters ({
    previousRoute:'previousRoute'
   }),
    expenseCategories() {
      let categories = this.$store.getters['appExpenseCategories/expenseCategories'].map((cat) => {
        let id = cat.id;
        let name = cat.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...categories];
    },
  },

  methods: {
    ...mapActions({
      getExpenseCategories: 'appExpenseCategories/getExpenseCategories',
      postExpenseType: 'appExpenseTypes/postExpenseType'
    }),
    async createExpenseType() {
      await this.postExpenseType(this.postExpenseTypeData).then(async (response) => {
        if (response.status === 201) {
          const toastObj = {message: 'Expense type created successful.', type: 'success'};
          this.showToastMessage(toastObj);

          await this.$router.replace({
            name: 'app.expense.type.list',
          });
        }
        this.errors.expense_category_id = response.errors && response.errors.expense_category_id ? response.errors.expense_category_id[0] : '';
        this.errors.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        if (response.message) {
          this.showToastMessage(response);
        }
      })
    }
  },
  mounted() {
    this.getExpenseCategories(this.getExpenseCategoriesParams);
  }
}
</script>

<style scoped>

</style>
